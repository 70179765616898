import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useLocation } from '@reach/router'
import styled from "styled-components"


const Wrapper = styled.div`
display:flex;
justify-content:flex-start;
align-items:flex-start;
flex: 1 0 auto;
min-height: 80vh;
`

const TextWrapper = styled.div`
margin-top:2rem;
margin-left:5rem;

`

const StyledText = styled.p`
margin:0.25rem;
`


export default function Page() {

    const location = useLocation();
    let country ="CH"
    let lang ="de-CH"
    
    const url = typeof window !=="undefined" ? window.location.href :"";
  
  
    if(url.includes(".com")){
      country ="DE"
    }
  
    return (
        <Layout location={country} path={location.pathname} language={lang}>
            <Seo title="Impressum" description="Impressum" lang="de"/>
            <Wrapper>
            <TextWrapper>
            <h1>Impressum</h1>
            <br/>
            <StyledText><b>DoryHealth AG</b></StyledText>
            <StyledText>Geschäftsführer: Stephan Gath</StyledText>
            <StyledText>Hagenholzstrasse 81A </StyledText>
            <StyledText>8050 Zürich, Schweiz</StyledText>
            <StyledText>Tel: +41445514374</StyledText>
            <StyledText>E-Mail: hello@doryhealth.com</StyledText>
            </TextWrapper>
            </Wrapper>


        </Layout>

    )

}